.div-blocks {
	border: 1px solid #21209c;
	border-radius: 6px;
	height: 70vw;
	margin: 2px;
	overflow: hidden;
}
.mvp-body {
	margin: 5px;
}
.button-wrapper {
	margin-top: 5px;
}

.upload-file-btn {
	padding: 10px 20px;
}
.download-file-btn {
	padding: 5px 10px;
	font-weight: 600;
	font-size: 12px;
	margin: 5px;
}

.selected-img {
	max-width: 99%;
}

.result-block {
	margin: 5px;
	overflow-y: scroll;
	max-height: 95%;
	overflow-x: hidden;
	word-wrap: break-word;
}

.progress-status {
	font-size: 18px;
	font-weight: 700;
	color: green;
	margin-top:10px;
}

.doc-type-select {
	margin-right: 10px;
	border-radius: 5px;
	border: 2px solid #21209c;
	padding: 10px 20px;
	font-weight: 500;
	font-size: 16px;
}