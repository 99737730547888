.navbar {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  bottom: auto;
  z-index: 999;
  padding-bottom: 1.25rem!important;
  padding-top: 1.25rem!important;
  background-color:#061E46;
  backdrop-filter: blur(9px);
}
.logInButtons {
  position: absolute;
  right: 95px;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-toggler:focus {
  border: none;
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
  color: #000;
}

.dropdown-menu {
  height: 3.75em;
  padding-right: 2em;
  padding-left: 2em;
  align-items: center;
  font-size: 14px;
  background-color: black;
}

.dropdown-item {
  position: relative;
  width: 100%;
  padding: 0.25rem 1rem;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover {
  background-color: black;
}

.header-nav .nav-link.active::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 27px;
    left: 0;
    border-bottom: 2px solid #fff;
}

.dropdown-item::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom: 1px solid #fff;
  transform: scaleX(0);
  transition: transform 0.5s;
  transform-origin: right;
}

.dropdown-item:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.dropdown:hover > .dropdown-menu {
  display: flex;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

.navbar-nav {
  /* margin-left: 2rem; */
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-item::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom: 1px solid #fff;
  transform: scaleX(0);
  transition: transform 0.5s;
  transform-origin: right;
}

.dropdown-item:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-item::before {
  content: "";
  position: absolute;
  width: 70%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #fff;
  transform: scaleX(0);
  transition: transform 0.5s;
  transform-origin: right;
}

.nav-item {
  position: relative;
}
/* .nav-item:hover::before {
  transform: scaleX(1);
  transform-origin: left;
} */
.nav-link {
  position: relative;
  padding: 0;
  display: inline-block;
  color: #fff;
}
@media (max-width:768px) {
  .logInButtons {
    position: relative;
    right: unset;
  }
  .nav-item {
    text-align: center;
    font-size: 18px;
    margin-bottom:4px;
  }
  .editProfileBtn {
    display:none;
  }
}
.nav-link:hover {
  color: #fff;
}

.dropdown-toggle::after {
  content: none;
}

/* button */

.btn.btn-sm {
  font-size: 0.95em;
}

.btn.btn-contact {
  margin-top: auto;
  border-color: var(--primary-color);
  background-color: #fff;
  color: var(--primary-color);
  transition: opacity 0.5s;
  padding: 0.6em 1.6em;
  font-weight: 800;
}

.btn.btn-logout {
  /* margin-top: auto; */
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: #fff;
  transition: opacity 0.5s;
  padding: 0.6em 1em;
  font-weight: 800;
}

.btn {
  position: relative;
  border-radius: 2;
  align-items: center;
  padding: 0.8em 2em;
  border: 2px solid var(--primary-color);
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

/* .btn.btn-contact:hover {
  opacity: 70%;
} */

.btn.btn-t:hover {
  white-space: nowrap;
  transition: all 500ms, color 0ms;
  flex: auto;
  color: #fff;
}
/* .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 40px 0 0 !important;
} */
