.footerMain {
  padding: 0px 80px;
  margin-top: 40px;
}
.footerContent {
  padding-top: 24px;
}

@media (max-width: 768px) {
  .footerMain {
	padding: 0px;
	margin-top: 20px;
  }
  .hideMobile {
	  display: none;
	  visibility: hidden;
  }
}
