.homePage {
  background-color: #03193e;
}
.video-class {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #000;
  object-fit: cover;
  /* filter: hue-rotate(-314deg) brightness(1.6); */
  left: 0;
}

.bar {
  position: relative;
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: #44cfcb;
  background-position: 50% 44%;
  background-size: cover;
}

.bar-container {
  display: flex;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.bar-text {
  border: 1px solid transparent;
  color: #fff;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  margin-right: 3rem;
}

.bar-btn.bar-btn-cyan {
  margin-top: auto;
  border-color: #44cfcb;
  background-color: #44cfcb;
  color: #000;
}

.bar-btn {
  position: relative;
  border-radius: 0;
  display: flex;
  align-items: center;
  min-width: 12.66em;
  padding: 0.5em 1em;
  border: 2px solid #c0a97d;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.7em;
}

.bar-btn.bar-btn-cyan:hover {
  border: 2px solid #44cfcb;
  background-color: inherit;
  color: #44cfcb;
}

.close {
  position: absolute;
  left: auto;
  top: 20%;
  right: 1%;
  bottom: auto;
  align-self: auto;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.close:hover {
  color: #fff;
}

/* Middle text */

.first-section {
  position: relative;
  display: flex;
  height: 89vh;
  -webkit-box-align: center;
  align-items: center;
  /* color: #fff; */
  padding-bottom: 3%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.first-section-text {
  position: relative;
  display: block;
  color: white;
  width: 50%;
  max-width: 60vw;
  margin-right: auto;
  margin-left: 100px;
}
.homePageFirstText {
  color: white;
  font-weight: 800;
  font-size: 55px;
  line-height: 70.44px;
}
.display-3-sub {
  color: #d24135;
  font-weight: 700;
  font-size: 55px;
  line-height: 70.44px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.btn-homePage,
.btn-homePage:hover,
.btn-homePage:focus,
.btn-homePage:active,
.btn-homePage:visited {
  margin-top: 12px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  border-radius: 6px;
  border: 1px solid #ffffff;
  text-transform: unset;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.homePage-section1-text3 {
  margin-top: 18px;
}
@media (max-width: 768px) {
  .first-section-text {
    margin-left: auto;
    width: 85%;
    max-width: unset;
  }
  .homePageFirstText {
    font-size: 40px;
    line-height: 3rem;
  }
  .display-3-sub {
    font-size: 40px;
    line-height: 3rem;
    text-align: center;
  }
  .btn-homePage {
    margin-top:16px;
    justify-content: center;
    display: flex;
  }
}

.max.max-877 {
  max-width: 54.8125em;
}

.max {
  width: 100%;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 5.3em;
  line-height: 1;
  font-weight: 300;
}

.scroll-down {
  display: none;
}
/* .lottie-container {
  position: relative;
}
.lottie-container::after {
  content: "";
  width: 70%;
  height: 0;
  background: #fff5;
  display: block;
  position: absolute;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  padding-bottom: 70%;
  left: 15%;
  box-shadow: 0 0 10px #7772;
} */
.footer-item::before {
  content: '';
  position: absolute;
  width: 40%;
  bottom: 0;
  border-bottom: 2px solid #fff;
  transform: scaleX(0);
  transition: transform 0.5s;
  transform-origin: right;
}

.footer-item {
  position: relative;
  cursor: pointer;
}

.footer-item:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.footer-icon {
  position: relative;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.card-link {
  color: #999;
  display: block;
  text-decoration: none;
  margin-top: 2rem;
}
.card-link:hover {
  color: #333;
}

.card-img-top {
  object-fit: cover;
  object-position: left;
}
@media (max-width: 768px) {
  .row.offers {
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 640px) {
  h1,
  .slider-text {
    text-align: center;
    font-size: 40px;
  }

  .scroll-down {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 8%;
    display: block;
    text-align: center;
  }
}
/* .navbar{box-shadow: 2px -1px 9px 2px #ddd; */
/* } */

.offer-list {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.offer-list.active {
  z-index: 2;
  color: #fff;
  background-color: #21209c;
  border: 1px solid #21209c;

  /* z-index: 2;
  color: #fff;
  background-color: #0d5caf;
  border-color: #0d5caf;
  font-weight: 700; */
  /* border-left: 2px solid #212529 ; */
}

/* @media (min-width: 992px) {
  .offers{
    display: none;
  }
} */

.contact-img {
  height: 400px;
}

.about-img {
  height: 400px;
}

.container.product {
  padding: 160px 0;
}

.blueColor {
  background-color: #03193e;
}

@media (max-width: 600px) {
  .row.offers {
    display: none;
  }

  .contact-img {
    height: 200px;
  }

  .about-img {
    height: 300px;
  }

  .contactImg2 {
    display: none;
  }

  .container.product {
    padding: 0;
  }

  .graphic-img {
    display: none;
  }
}
