/* .form-control:focus{
    border-color: #ced4da;
} */

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: white;
}

.input{
  background-color: white;
}

.form-select:focus{
  border-color: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
