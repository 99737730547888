.whatwedo-details {
  margin-top: 65px;
}
.whatwedo-slides-wrapper {
	padding:6px;
}
.whatwedo-slides-data {
	padding:6px;
  display: table-cell;
  vertical-align: middle;
}
.whatWeDo-TRBL {
  background: #061e46;
  border-radius: 0px 80px;
  height: 310px;
}
.whatWeDo-TLBR {
  height: 310px;
  background: #061e46;
  border-radius: 80px 0px;
}
.whatwedo-list-heading {
	font-weight: bold;
font-size: 20px;
line-height: 23px;
margin-bottom:12px;
}
.whatwedo-list-text {
	font-weight: 500;
font-size: 18px;
line-height: 23px;
padding:10px;
justify-content:center;
color: #9BA3B0;
}

@media (max-width:768px) {
	.whatwedo-slides-wrapper {
		padding: 10px 7.5vw;
	}
	.whatWeDo-TRBL,.whatWeDo-TLBR {
		width: 85vw;
	}
}