.carousel .slide img {
    vertical-align: unset;
}
.btn-label {
    border: 0;
    border-bottom: 1px solid black;
    margin: 0px 10px;
}
.demoRightPane {
    text-align: center;
    padding-top: 20px;
}
.statusText {
    color: green;
    margin-top: 15px;
}
.carousel-main {
    margin-top: 15px;
    border: 1px solid black;
}
.carousel-block {
    padding: 10px;
}
.productBody {
    min-height: 50vh;
}
.productBodyLeft, .productBodyRight {
    height: 100%;
}
.btn-upload {
    border: 0;
    border-bottom: 1px solid #03193e;
    color: #03193e;
}