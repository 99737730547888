.receiptAnnotation {
    margin: 15px;
    height: 100vh;
}
.receiptImage {
    max-width: 100%;
}
.imageBlock {
    height: 100vh;
    overflow-y: scroll;
}
.receiptForm {
    padding: 10px;
    height: 100vh;
    overflow-y: scroll;
}
.menuActionBtn {
    margin-right: 6px;
}
.annotationSubmitBtn, .skipBtn {
    width: 45%;
    display: inline-block;
    margin-right: 10px;
}
.userTotal {
    font-size: 1.1em;
    border: 1px solid;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 6px;
}