.preProcessingBlock {
    height: 100vh;
}
.baseURLInput {
    margin: 10px;
}
.baseImageDiv, .returnImageDiv {
    border: 1px solid black;
    border-radius: 10px;
    padding: 0;
}
.baseImage {
    padding: 4px;
    max-width: 100%;
    max-height: 100%;
}
.preprocess-option {
    border: 0;
    padding: 0;
    margin-bottom: 2px;
}
.preProcessingImages {
    margin: 10px;
    height: 85%;
}
.submitBtn {
    margin-top: 10px;
    font-size: 1rem;
}