:root {
  --primary-color: #21209c;
  --secondary-color: #D24135;
  --tertiary-color: #9c5e20;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: "Manrope", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

body {
  font-family: var(--bs-font-sans-serif);
  letter-spacing: 1.2px;
}

.image-bg {
  position: fixed;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.horizontal-scroll-container {
  position: relative;
  height: 100vh;
  background: #071627;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.tertiary {
  color: var(--tertiary-color) !important;
}
.bg-tertiary {
  background-color: var(--tertiary-color) !important;
}

.primary {
  color: var(--primary-color) !important;
}
.bg-primary {
  background-color: var(--primary-color) !important;
}

.secondary {
  color: var(--secondary-color) !important;
}
.bg-secondary {
  background-color: var(--secondary-color) !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
  }
}

.wrapper-list li {
  color: #183b56;
  font-size: 20.05px;
  font-weight: 600;
  letter-spacing: 0.23px;
  line-height: 35.78px;
  display: inline-block;
  padding-left: 15px;
  margin-bottom: 20px;
}

iframe {
  height: 0 !important;
}

div#via_container {
  position: relative;
}

#via_start_info_content{
  grid-area: 2;
}

.drag-area.color-blue {
  position: absolute;
  left: 0;
  right:0;
  top:0;
  bottom: 0;
  border: 0px dashed #000;
  color: var(--primary-color);
}

/* .column.card {
  margin-bottom: 20px;
} */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1 !important;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.7;
}

ul.navbar-nav.header-nav .nav-item {
  padding: 5px 0;
  margin-right: 5px;
}
.navbar-toggler:focus {
  outline: 0;
  box-shadow: none !important;
}
.copy-right p {
  margin: 0;
}
ul.footer-link {
  padding: 0;
}
ul.social-icon li {
  list-style: none;
  float: left;
  padding-right: 12px;
  background: #000;
  padding: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100%;
}
ul.social-icon {
  padding: 0;
}

ul.social-icon li {
  list-style: none;
  float: left;
  padding-right: 12px;
}

.copy-right {
  padding: 10px;
  /* background: rgb(13, 13, 62); */
  text-align: center;
}

ul.footer-link li {
  list-style: none;
  padding: 0 0 15px;
  margin-bottom: 10px;
}

.footer-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h4.footer-head {
  font-size: 20px;
  margin-bottom: 20px;
}
ul.footer-link li a {
  text-decoration: none;
  color: #fff;
}

@media (max-width: 600px) {
  .drag-area.color-blue {
    /* left: 50%;
    top: 45%; */
    padding: 130px 0 !important;
  }
  .footer-block {
    display: block;
  }
  ul.navbar-nav.header-nav {
    margin-left: 10px;
    border-top: 1px solid #b198b1;
    margin-top: 10px;
  }

  .column.card {
    margin-top: 20px;
  }
}

@media (max-width: 414px) {
  button#logoutButton,
  button.btn.btn-sm.btn-logout {
    width: 100%;
    margin: 10px 0;
  }
}
