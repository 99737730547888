.sectionContact {
	background: #061E46;
border-radius: 24px;
text-align: center;
padding:24px;
margin-top:
}
.sectionHeadingContact {
	/* margin-bottom: 48px; */
}
.contactForm {
	padding: 50px;
}

.contactEmail, .contactName {
	width: 46%;
	display: inline-block;
	height: 64px;
}
.contactName {
	margin-right: 8%;
}
.contactEmail, .contactName, .contactMessage,
.contactEmail:hover, .contactName:hover, .contactMessage:hover,
.contactEmail:active, .contactName:active, .contactMessage:active,
.contactEmail:focus, .contactName:focus, .contactMessage:focus {
	background-color: #03193E;
	border-color: #03193E;
	box-shadow:unset;
	border-radius: 8px;
	padding: 0.75rem;
	color: white;
}
.contactMessage {
	margin-top: 24px;
}
.contactSubmit {
	margin-top: 24px;
	background: #D24135;
border-radius: 80px;
padding: 16px 60px;
border:0;
box-shadow: unset;
}

@media (max-width:768px) {
	.sectionHeadingContact {
		margin-bottom:10px;
	}

	.contactForm {
		padding: 0px;
	}
	.contactEmail, .contactName, .contactMessage {
		width: 100%;
		margin: 5px 0px 0px 0px;
	}
}