.sectionMain {
  color: white;
  align-items: center;
  text-align: center;
  padding: 0px 80px;
}
.colorSec {
  color: var(--secondary-color);
}
.sectionHeading {
  font-weight: 800;
  font-size: 35px;
  line-height: 47px;
  margin-top: 96px;
}
.sectionSubHeading {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #9ba3b0;
  width: 40%;
  margin: auto;
}
.benefits-details {
  margin-top: 65px;
}
.benefits-list-heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
}
.benefits-list-image {
  width: 28px;
  margin-right: 20px;
}
.benefits-list-text {
  font-size: 16px;
  line-height: 22px;
  color: #9ba3b0;
  margin-top: 15px;
  word-spacing: 1px;
  text-align: left;
}
.benefits-list-headingText {
  text-align: center;
}

@media (max-width:768px) {
  .sectionMain {
    padding: 0px 20px;
  }
	.sectionHeading {
		margin-top: 48px;
	}
	.sectionSubHeading {
		width: 90%;
	}
  .benefits-list-wrapper {
    margin-bottom: 36px;
  }
  .benefits-list-image {
    width: 30px;
  }
	.benefits-list-heading {
		margin-top: 24px;
    font-size: 22px;
    text-align: center;
	}
	.benefits-list-text {
      font-size: 18px;
      line-height: 30px;
		margin-top:12px;
    text-align: center;
	}
}